module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"نوید بهروزی مجد - طراح و توسعه دهنده سایت","short_name":"Navid Behroozi","start_url":"/","background_color":"#ffffff","theme_color":"#101010","display":"standalone","icon":"src/assets/images/icons/icon-144x144.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5b67455621249c8ed5e3239f214b1815"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
